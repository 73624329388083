import create from "zustand";
import { devtools, persist } from "zustand/middleware";

const Store = create(
  persist(
    devtools((set) => ({
      loggedUser: {},
      forgotUser: {},
      memberStatus: {},
      memberType: {},
      setLoggedUser: function (value) {
        set({ loggedUser: value });
      },
      setForgotUser: function (value) {
        set({ forgotUser: value });
      },
      setOtpDate: function (value) {
        set({ otpDate: value });
      },
      setMemberStatus: function (value) {
        set({ memberStatus: value });
      },
      setMemberType: function (value) {
        set({ memberType: value });
      },
    })),
    {
      name: "my-store", // unique name
      storage: localStorage, // or sessionStorage
    }
  )
);

export default Store;
