import React from "react";

import { useNavigate } from "react-router-dom";

const CardItem = ({ image, title, snippet, id }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(`/blog/${title.trim().replace(/\s+/g, "-")}-${id}`);
  };

  return (
    <div className="col-lg-4 col-md-6">
      <div className="card">
        <img src={image} className="d-block w-100" alt={title} />
        <div className="card-body">
          <h5 className="card-title" onClick={handleNavigation}>
            {title}
          </h5>

          <p className="card-text">{snippet}</p>
        </div>
        <div className="card-footer">
          <div className="text-center">
            <button onClick={handleNavigation} className="btn btn-outline-dark">
              Read more{" "}
              <span>
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.8738 7.59263L19.8748 15.5643L18.5793 15.5652L18.5793 9.80276L8.23996 20.1421L7.32441 19.2265L17.6637 8.88721L11.9022 8.88813L11.9022 7.59171L19.8738 7.59263Z"
                    fill="black"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardItem;
