import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import logo from "../assets/images/logo.png";
import menuLeft from "../assets/images/menu-left.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Store from "../zustand/store";
import { INFO_SHOW_URL } from "../constant/constant";
import helper from "../helper/authHelper";
import useFetch from "../hook/useFetch";

const Navbar = (props) => {
  let Username = JSON.parse(localStorage.getItem("Username"));
  const { pathname } = useLocation();
  let validates = false;
  const [isLandPage, setIsLandPage] = useState("true");
  let user = JSON.parse(localStorage.getItem("LogUser"));
  let Fname = JSON.parse(localStorage.getItem("fname"));
  let Email = JSON.parse(localStorage.getItem("useEmail"));
  const navigate = useNavigate();
  const [response, , , fetchData] = useFetch();

  let memberStatusStorage = localStorage.getItem("MemberStatus");
  const { MemberType } = props;

  const { setLoggedUser, setMemberStatus, loggedUser, memberStatus } = Store();

  user ? (validates = true) : (validates = false);
  // Navbar titles
  const navArr = [
    { path: "/user/dashboard", title: "Home" },
    { path: "/user/testimonial", title: "Testimonial" },
    { path: "/user/research", title: "Research" },
    { path: "/user/subscription", title: "Subscribe" },
    { path: "/user/managegroup", title: "Manage Group" },
    { path: "/research/information", title: "Information" },
    // { path: "/blogs", title: "Blogs" },
    // { path: "/faq", title: "FAQ's" },
    // { path: "/contact-us", title: "Contact Us" },
  ];

  useEffect(() => {
    pathname === "/" ? setIsLandPage(true) : setIsLandPage(false);
  }, [pathname]);

  const URL = `/check/auth?Username=${Username}`;
  // Get Information
  const getData = async () => {
    try {
      const allInfo = await fetchData({
        url: URL,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          authorization: JSON.parse(localStorage.getItem("LogUser")),
        },
      });
      if (allInfo?.status === 401) {
        helper(navigate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        // console.log("inside visible", localStorage?.LogUser);
        getData();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const location = useLocation();

  return (
    <>
      <header>
        <nav className="navbar navbar-expand-md main-header-section">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              <div className="logoContent ">
                <img src={logo} alt="" className="img-fluid" />
              </div>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#mynavbar"
            >
              <img src={menuLeft} alt="" />
            </button>
            <div className="collapse navbar-collapse" id="mynavbar">
              <ul className="navbar-nav ms-auto">
                {navArr?.map((item, index) => {
                  if (
                    (!user && item.path === "/user/managegroup") ||
                    (Username && item.path === "/user/managegroup")
                  ) {
                    return null;
                  } else if (
                    ((memberStatus === "ACTIVE" ||
                      memberStatusStorage === "ACTIVE") &&
                      (MemberType === "monthly" ||
                        MemberType === "yearly" ||
                        MemberType == "EMPTY") &&
                      item.path == "/user/managegroup") ||
                    ((memberStatus === "EMPTY" ||
                      memberStatus == null ||
                      memberStatusStorage == "EMPTY" ||
                      memberStatusStorage == null) &&
                      item.path == "/user/managegroup")
                  ) {
                    return null;
                  } else {
                    return (
                      <li
                        key={index + "ind"}
                        className={
                          item?.path === pathname
                            ? "nav-item text-underline"
                            : "nav-item"
                        }
                      >
                        <Link className="nav-link " to={item.path}>
                          {item.title}
                        </Link>
                      </li>
                    );
                  }
                })}

                <Dropdown className="award-dropdown cstm-award-drpdwn">
                  <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                    Get in Touch
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="cstm-nav-link">
                    <Dropdown.Item
                      as={Link}
                      to="/contact-us"
                      className={
                        location.pathname === "/contact-us"
                          ? "nav-item subActive  "
                          : ""
                      }
                    >
                      Contact Us
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      to="/connect-with-us"
                      className={
                        location.pathname === "/connect-with-us"
                          ? "subActive"
                          : ""
                      }
                    >
                      Social Media
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      to="/faq"
                      className={
                        location.pathname === "/faq" ? "subActive" : ""
                      }
                    >
                      FAQ's
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      to="/blogs?currentPage=1"
                      className={
                        location.pathname === "/blogs" ? "subActive" : ""
                      }
                    >
                      Blogs
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="award-dropdown">
                  <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                    Achievement
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/liveInk/awards">Awards</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
              <div className="userTop ms-auto">
                <div className="dropdown">
                  {validates ? (
                    <>
                      <button
                        type="button"
                        className="btn dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        <span>{Fname}</span>
                      </button>
                      <ul className="dropdown-menu">
                        <Link className="dropdown-item" to="/user/profile">
                          My Profile
                        </Link>
                        <Link className="dropdown-item" to="/logout">
                          Logout
                        </Link>
                      </ul>
                    </>
                  ) : (
                    <>
                      {!isLandPage ? (
                        <>
                          <div className="gstarted">
                            <Link className="get-str text-center" to="/login">
                              {" "}
                              Login
                            </Link>
                            <Link
                              className="get-str text-center"
                              to="/registration"
                            >
                              {" "}
                              Sign Up
                            </Link>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
