import React from "react";

const Popup = ({ isVisible, onClose, content }) => {
  if (!isVisible) return null;

  return (
    <>
      {/* Full-page popup */}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "#fff",
          zIndex: 1000,
          overflow: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: "20px",
            maxWidth: "100%",
            width: "100%",
            background: "#f1f1f1",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            overflow: "auto",
            maxHeight: "90%",
          }}
        >
          <h2
            style={{
              textAlign: "center",
              marginBottom: "20px",
              color: "#333",
            }}
          >
            Preview Content
          </h2>
          <div
            style={{
              padding: "20px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              backgroundColor: "#fff",
              minHeight: "50vh",
              maxHeight: "70vh",
              overflowY: "auto",
            }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <button
            onClick={onClose}
            style={{
              marginTop: "20px",
              background: "#007bff",
              color: "#fff",
              border: "none",
              padding: "10px 20px",
              borderRadius: "4px",
              cursor: "pointer",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Close
          </button>
        </div>
      </div>

      {/* Overlay */}
      <div
        onClick={onClose}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 999,
        }}
      />
    </>
  );
};

export default Popup;
