import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Navbar from "../../../Navbar";
import Footer from "../../../Footer";
import Loader from "../../../loader/Loader";

import "./BlogDetails.css";

import dayjs from "dayjs";
import { BASEURL } from "../../../../constant/constant";
import { apiCall } from "../../../../API/apiCall";
import { toast } from "react-toastify";
import BlogEditor from "../../../Admin/blogs/Editor/Editor";

const MemberTyp = localStorage.getItem("MemberType");

const BlogDetails = () => {
  const [isLoader, setisLoader] = useState(false);
  const [blog, setBlog] = useState([]);

  const { name } = useParams();
  const parts = name.split("-");
  const id = parts.pop();

  useEffect(() => {
    setisLoader(true);
    const getSingleBlog = async () => {
      try {
        const response = await apiCall({
          url: `/blogs/${id}`,
          method: "GET",
        });
        setBlog(response?.data?.data);
        setisLoader(false);
      } catch (error) {
        toast.error(error?.response?.data?.message || error.message);
        setisLoader(false);
      }
    };
    getSingleBlog();
  }, []);

  const replaceImagePlaceholders = (content, contentImages) => {
    console.log("TTTTTTTTTTTTTTTTTTTT : ", content);
    console.log("TTTTTTTTTTTTTTTTTTTT 2222222 : ", contentImages);
    // Replace placeholders like {imageIndex} with actual image paths
    return content?.replace(/\{image(\d+)\}/g, (_, index) => {
      const imageUrl = contentImages[parseInt(index, 10)];
      return imageUrl
        ? `<img class="blog-content-image" src="${`${BASEURL}/${imageUrl}`}" alt="Blog Image" style="max-width: 100%; height: auto;" />`
        : "";
    });
  };

  return (
    <>
      <Navbar MemberType={MemberTyp} />
      {isLoader && <Loader />}
      {!isLoader && (
        <div className="container py-4">
          <div className="detailprofile-outer">
            <div className="blog-title">
              <h1>{blog.title}</h1>
            </div>
            <div className="details-profile">
              <img src={`${BASEURL}/${blog.author?.profile}`} />
              <div>
                <h6>
                  {blog.author?.firstName && blog.author?.lastName
                    ? `${blog.author.firstName} ${blog.author.lastName}`
                    : blog.author?.lastName
                    ? "Anonymous"
                    : blog.author?.firstName
                    ? blog.author.firstName
                    : "Anonymous"}
                </h6>
                <p> {dayjs(blog.createdAt).format("DD MMM YYYY . hh:mm A")}</p>
              </div>
            </div>
            <div className="blog-image">
              <img
                src={`${BASEURL}/${blog.coverImage}`}
                alt="cover-image.jpg"
              />
            </div>
            <div className="blog-content">
              {/* <div dangerouslySetInnerHTML={{ __html: blog.content }} /> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: replaceImagePlaceholders(
                    blog.content,
                    blog.contentImages
                  ),
                }}
              />
            </div>
          </div>
        </div>
      )}
      {/* <div>
        <BlogEditor></BlogEditor>
        <h1>Hello Ali</h1>
      </div> */}
      {!isLoader && <Footer />}
    </>
  );
};

export default BlogDetails;
