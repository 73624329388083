import React from "react";
import "../card/card.css";
import { oopsText } from "../../../../../src/constant/constant";
import CardItem from "./CardItem";
import { BASEURL } from "../../../../constant/constant";
import oops from "../../../../../src/assets/images/oops.png";

export const Card = ({ blogs }) => {
  return (
    <>
      <div className="blog-card-outer custom-blog-card">
        <div className="row">
          {blogs?.length ? (
            blogs?.map((blog, index) => (
              <CardItem
                key={index}
                image={`${BASEURL}/${blog.coverImage}`}
                title={blog.title}
                snippet={blog.snippet}
                id={blog._id}
              />
            ))
          ) : (
            <div className="oops-div d-flex justify-content-center align-items-center flex-column">
              <img src={oops} />
              <p>{oopsText}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Card;
